import {
  Box,
  Flex,
  Group,
  Title,
  Space,
  Button,
  HoverCard,
  Card,
} from "@mantine/core";
import PasswordField from "../../component/form/password-field/Index";
import InputField from "../../component/form/input-field/InputField";
import { useDisclosure } from "@mantine/hooks";
import { Calendar, DateInput } from "@mantine/dates";
import React, { useState } from "react";
import DateTile from "./component/DateTile";
import { IconPlus } from "@tabler/icons-react";

import ThemeButton from "../../component/button/ThemeButton";
import HolidayModal from "./component/HolidayModal";
import classes from "./index.module.css";
function Leave() {
  const renderDay = (date: Date) => {
    const day = date.getDate();
    const holiday = [2, 16];
    const isAlternateSaturday =
      date.getDay() === 6 && Math.floor((day - 1) / 7) % 2 === 1;
    const isSunday = date.getDay() === 0;
    var isHoliday = false;

    holiday.map((day) => {
      if (day === date.getDate()) isHoliday = true;
      return;
    });
    console.log(isHoliday, date.getDate());

    if (!isAlternateSaturday && !isSunday && !isHoliday) {
      return (
        <HoverCard radius="md" shadow="md" openDelay={500}>
          <HoverCard.Target>
            <div style={{ color: "black" }}>{day}</div>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Button>Assign Holiday</Button>
          </HoverCard.Dropdown>
        </HoverCard>
      );
    }

    return (
      <HoverCard openDelay={500}>
        <HoverCard.Target>
          <div style={{ color: "red" }}>{day}</div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <DateTile />
        </HoverCard.Dropdown>
      </HoverCard>
    );
  };

  return (
    <Box>
      <Flex mih={50} gap="md" justify="center" align="center" direction="row">
        <Group>
          <Title order={2} weight={500} c="gray">
            Holiday Calendar
          </Title>
        </Group>
      </Flex>
      <Space h="lg" />
      <HolidayModal />
      <Space h="xl" />

      <Flex direction="row" justify="space-around" wrap="wrap">
        <Card shadow="sm" padding="lg" radius="md" withBorder miw={450}>
          <Flex direction="column" align="center" justify="center">
            <Title order={3} weight={400} c="gray">
              Upcoming Holidays
            </Title>
            <DateTile />
            <DateTile />
            <DateTile />
          </Flex>
        </Card>

        <Card shadow="lg" radius="lg" withBorder mah={500}>
          <Calendar
            size="xl"
            color="red"
            hideOutsideDates
            weekendDays={[]}
            renderDay={renderDay}
            // classNames={{
            //   calendarHeader: classes.header,
            //   calendar: classes.calendar,
            //   day: classes.weekday,
            // }}
          />
        </Card>
      </Flex>
      <Space h="lg" />
    </Box>
  );
}

export default Leave;
