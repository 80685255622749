import React, { useState } from "react";
import {
  Modal,
  Flex,
  Box,
  Text,
  TextInput,
  Button,
  Group,
  rem,
  Switch,
} from "@mantine/core";
import ThemeButton from "../../../component/button/ThemeButton";
import { IconPlus, IconUpload } from "@tabler/icons-react";
import { DateInput } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import {
  Dropzone,
  DropzoneProps,
  MS_EXCEL_MIME_TYPE,
  FileWithPath,
} from "@mantine/dropzone";

function HolidayModal(props: Partial<DropzoneProps>) {
  const [holidayDate, setHolidayDate] = useState<Date | null>(null);
  const [uploadFile, setUploadFile] = useState<boolean>(false);
  const [holidayFile, setHolidayFile] = useState<FileWithPath[] | null>(null);
  const [holidayName, setHolidayName] = useState<string>("");
  const [holidayType, setholidayType] = useState<string>("");
  const [opened, { open, close }] = useDisclosure(false);

  function onHolidayDateChange(value: Date) {
    setHolidayFile(null);
    setHolidayDate(value);
  }

  function onHolidayTypeChange(value: string) {
    setHolidayFile(null);
    setholidayType(value);
  }

  function onHolidayNameChange(value: string) {
    setHolidayFile(null);
    setHolidayName(value);
  }

  function onHolidayFileChange(value: FileWithPath[]) {
    setHolidayDate(null);
    setHolidayName("");
    setholidayType("");
    setHolidayFile(value);
  }

  return (
    <Flex direction="row" justify="end">
      <Box onClick={open}>
        <ThemeButton
          title="Add Holiday"
          mr={15}
          leftIcon={<IconPlus size={20} />}
        />
      </Box>

      <Modal
        padding={30}
        styles={{
          title: { fontSize: "1.3rem", fontWeight: 500 },
          close: {
            color: "#ff008a",
            "&:hover": {
              backgroundColor: "#ff008a",
              color: "white",
              transition: " all 0.2s ease-in-out 0s;",
            },
          },
        }}
        opened={opened}
        onClose={close}
        title={"Add Holiday"}
        centered
      >
        <Switch
          checked={uploadFile}
          onChange={(e) => setUploadFile(e.target.checked)}
          labelPosition="left"
          label="Upload File"
          color="indigo"
        />
        {!uploadFile ? (
          <>
            <Box style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <Text
                mb={15}
                style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}
              >
                Date
              </Text>
              <DateInput
                disabled={uploadFile}
                clearable
                value={holidayDate}
                onChange={onHolidayDateChange}
              />
            </Box>
            <Box style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <Text
                mb={15}
                style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}
              >
                Category
              </Text>
              <TextInput
                disabled={uploadFile}
                value={holidayType}
                placeholder=""
                onChange={(e) => onHolidayTypeChange(e.target.value)}
              />
            </Box>
            <Box style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <Text
                mb={15}
                style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}
              >
                Name
              </Text>
              <TextInput
                disabled={uploadFile}
                value={holidayName}
                placeholder=""
                onChange={(e) => onHolidayNameChange(e.target.value)}
              />
            </Box>
          </>
        ) : (
          <>
            <Text
              mb={15}
              style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}
            >
              Upload File
            </Text>
            <Dropzone
              disabled={!uploadFile}
              onDrop={(file) => onHolidayFileChange(file)}
              maxFiles={1}
              onReject={(files) => console.log("rejected files", files)}
              maxSize={3 * 1024 ** 2}
              accept={MS_EXCEL_MIME_TYPE}
              {...props}
            >
              <Group
                position="center"
                spacing="xl"
                style={{ minHeight: rem(220), pointerEvents: "none" }}
              >
                {holidayFile === null ? (
                  <div>
                    <Text size="xl" inline>
                      Drag file here or click to select files
                    </Text>
                  </div>
                ) : (
                  <div>
                    <Text size="xl" inline>
                      File uploaded Succesfully
                    </Text>{" "}
                    <Text>{holidayFile[0].name}</Text>
                  </div>
                )}
              </Group>
            </Dropzone>
          </>
        )}

        <Button type="submit" fullWidth mt="xl">
          Add Holiday
        </Button>
      </Modal>
    </Flex>
  );
}

export default HolidayModal;
