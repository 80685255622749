import React from "react";
import {
  Card,
  Text,
  Group,
  Box,
  Indicator,
  Center,
  Divider,
} from "@mantine/core";

function DateTile() {
  return (
    <Card shadow="md" radius="md" padding="lg" m={5} miw={400}>
      <Group style={{ display: "flex", justifyContent: "space-between" }}>
        <Text fz="md" fw={600} color="dark">
          12-September-2024
        </Text>
        <Box>
          <Center>
            <Indicator inline size={8} position="middle-start" color={"yellow"}>
              <Text ml={10} size="xs" weight={500}>
                Upcoming
              </Text>
            </Indicator>
          </Center>
        </Box>
      </Group>
      <Divider my="sm" />
      <Group style={{ display: "flex", justifyContent: "space-between" }}>
        <Text fz="sm" fw={400} c="dimmed">
          Category
        </Text>

        <Text ml={10} size="xs" weight={500}>
          Weekend Holiday
        </Text>
      </Group>
      <Group style={{ display: "flex", justifyContent: "space-between" }}>
        <Text fz="sm" fw={400} c="dimmed">
          Name
        </Text>

        <Text ml={10} size="xs" weight={500}>
          -
        </Text>
      </Group>
    </Card>
  );
}

export default DateTile;
